import axios from 'axios'

export default {
  getSales (data) {
    return axios.get(
      '/dms_sales/query?start_date=' + data.startDate + '&end_date=' + data.endDate + '&apr=' + data.apr + '&apr_operator=' + data.aprOperator + '&payment=' + data.payment +
      '&payment_operator=' + data.paymentOperator + '&months_remaining=' + data.paymentsRemaining + '&months_remaining_operator=' + data.paymentsRemainingOperator +
      '&months_since_sale=' + data.monthsSinceSale + '&months_since_sale_operator=' + data.monthsSinceSaleOperator +
      '&term_complete_percent=' + data.termCompletePercent + '&term_complete_percent_operator=' + data.termCompletePercentOperator +
      '&make=' + data.makes + '&model=' + data.models + '&year=' + data.years + '&states=' + data.states +
      '&header_text=' + data.headerText + '&header_values=' + data.headerValues +
      '&campaign_name=' + data.campaignName + '&campaign_date=' + data.campaignDate +
      '&limit=1000',
    )
  },
  getService (data) {
    return axios.get(
      '/dms_service/query?start_date=' + data.startDate + '&end_date=' + data.endDate +
      '&make=' + data.makes + '&model=' + data.models + '&year=' + data.years + '&states=' + data.states +
      '&header_text=' + data.headerText + '&header_values=' + data.headerValues +
      '&campaign_name=' + data.campaignName + '&campaign_date=' + data.campaignDate +
      '&start_last_serviced_date=' + data.startLastServicedDate +
      '&end_last_serviced_date=' + data.endLastServiceDate +
      '&report_type=' + data.reportType +
      '&limit=1000',
    )
  },
  emailSalesReport (data) {
    return axios.get(
      '/dms_sales/email_report?start_date=' + data.startDate + '&end_date=' + data.endDate + '&apr=' + data.apr + '&apr_operator=' + data.aprOperator + '&payment=' + data.payment +
      '&payment_operator=' + data.paymentOperator + '&months_remaining=' + data.paymentsRemaining + '&months_remaining_operator=' + data.paymentsRemainingOperator +
      '&months_since_sale=' + data.monthsSinceSale + '&months_since_sale_operator=' + data.monthsSinceSaleOperator +
      '&term_complete_percent=' + data.termCompletePercent + '&term_complete_percent_operator=' + data.termCompletePercentOperator +
      '&make=' + data.makes + '&model=' + data.models + '&year=' + data.years + '&states=' + data.states +
      '&header_text=' + data.headerText + '&header_values=' + data.headerValues,
    )
  },
  emailServiceReport (data) {
    return axios.get(
      '/dms_service/email_report?start_date=' + data.startDate + '&end_date=' + data.endDate +
      '&start_last_serviced_date=' + data.startLastServicedDate +
      '&end_last_serviced_date=' + data.endLastServiceDate +
      '&make=' + data.makes + '&model=' + data.models + '&year=' + data.years + '&states=' + data.states +
      '&header_text=' + data.headerText + '&header_values=' + data.headerValues,
    )
  },
  getSalesMakes () {
    return axios.get(
      '/dms_sales/makes',
    )
  },
  getSalesModels () {
    return axios.get(
      '/dms_sales/models',
    )
  },
  getSalesYears () {
    return axios.get(
      '/dms_sales/years',
    )
  },
  getServiceMakes () {
    return axios.get(
      '/dms_service/makes',
    )
  },
  getServiceModels () {
    return axios.get(
      '/dms_service/models',
    )
  },
  getServiceYears () {
    return axios.get(
      '/dms_service/years',
    )
  },
  getServiceStates () {
    return axios.get(
      '/dms_service/states',
    )
  },
  getSalesStates () {
    return axios.get(
      '/dms_sales/states',
    )
  },
  getCampaigns (campaignType) {
    return axios.get(
      '/campaigns?campaign_type=' + campaignType,
    )
  },
  updateCampaign (data) {
    return axios.patch(
      '/campaigns/' + data.id + '?name=' + data.name + '&campaign_date=' + data.campaignDate,
    )
  },
  deleteCampaign (campaignId) {
    return axios.delete(
      '/campaigns/' + campaignId,
    )
  },
  getCampaign (campaignId) {
    return axios.get(
      '/campaigns/' + campaignId,
    )
  },
  publicGetCampaign (campaignId, aid) {
    return axios.get(
      '/public_campaigns/' + campaignId + '?aid=' + aid,
    )
  },
  createCampaignSummary (data) {
    return axios.post(
      '/campaigns/' + data.campaignId + '/create_campaign_summary?start_date=' + data.startDate + '&end_date=' + data.endDate + '&mailed=' + data.mailed,
    )
  },
  deleteCampaignSummary (campaignSummaryId) {
    return axios.delete(
      '/campaign_summaries/' + campaignSummaryId,
    )
  },
  campaign_summary_matchback_dataset (campaignSummaryId) {
    return axios.get(
      '/campaign_summaries/' + campaignSummaryId + '/matchback_dataset',
    )
  },
  getImportBatches () {
    return axios.get(
      '/dms/import_batches',
    )
  },
  getScheduledReports () {
    return axios.get(
      '/dms/reporting/scheduled_dms_reports',
    )
  },
  getScheduledReport (reportId) {
    return axios.get(
      '/dms/reporting/scheduled_dms_reports/' + reportId,
    )
  },
  deleteScheduledReport (reportId) {
    return axios.delete(
      '/dms/reporting/scheduled_dms_reports/' + reportId,
    )
  },
  updateScheduledReport (data) {
    return axios.patch(
      '/dms/reporting/scheduled_dms_reports/' + data.reportId + '?title=' + data.title + '&frequency=' + data.frequency + '&headers=' + data.headers + 
      '&ftp_server=' + data.ftpServer + '&ftp_login=' + data.ftpLogin + '&ftp_password=' + data.ftpPassword + '&ftp_directory=' + data.ftpDirectory +
      '&contact_person=' + data.contactPerson + '&contact_title=' + data.contactTitle
    )
  },
  runScheduledReport (reportId) {
    return axios.post(
      '/dms/reporting/scheduled_dms_reports/' + reportId + '/run_report',
    )
  },
  getScheduledReportFiles (reportId) {
    return axios.get(
      '/dms/reporting/scheduled_dms_reports/' + reportId + '/scheduled_dms_report_files',
    )
  },
  deleteScheduledReportFile (reportId, id) {
    return axios.delete(
      '/dms/reporting/scheduled_dms_reports/' + reportId + '/scheduled_dms_report_files/' + id,
    )
  },  
}
