var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"regular-tables","fluid":"","tag":"section"}},[_c('base-material-card',{staticStyle:{"background-color":"#FAFAFA"},attrs:{"icon":"mdi-alarm-multiple","title":"Mailers","color":"secondary"}},[_c('v-row',[_c('v-col',{staticClass:"text-right"},[_c('a',{attrs:{"href":"https://api.dealerinsights.com/scheduled_reports.pdf","target":"_blank"}},[_vm._v(" How are these reports generated? ")])])],1),_c('v-col',[_c('v-card',[_c('v-data-table',{attrs:{"loading":_vm.loading,"loading-text":"Loading... Please wait","headers":_vm.headers,"items":_vm.items,"items-per-page":50,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.query_type",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(value))+" ")]}},{key:"item.frequency",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(value))+" ")]}},{key:"item.last_run_date",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatIsoDate(value, true))+" ")]}},{key:"item.next_run_date",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatIsoDate(value, true))+" ")]}},{key:"item.scheduled_dms_report_files",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'Mailer Files', params: { report_id: item.report_id } }}},[_vm._v(" "+_vm._s(_vm.formatNumber(item.scheduled_dms_report_files.length))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.runReport(item)}}},on),[_vm._v(" mdi-reload ")])]}}],null,true)},[_c('span',[_vm._v("Run Report Now")])]),_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"to":{ name: 'Edit Mailer', params: { report_id: item.report_id } }}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""}},on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Edit Mailer")])])],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Delete Mailer")])])]}}])})],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.showErrorDialog = false}},model:{value:(_vm.showErrorDialog),callback:function ($$v) {_vm.showErrorDialog=$$v},expression:"showErrorDialog"}},[_c('error-message',{attrs:{"error":_vm.error},on:{"closeErrorDialog":_vm.closeErrorDialog}})],1),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"300"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card',{attrs:{"color":"#FF5E40","dark":""}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.loadingMessage)+" "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","width":"600"},model:{value:(_vm.noPermissions),callback:function ($$v) {_vm.noPermissions=$$v},expression:"noPermissions"}},[_c('v-card',{attrs:{"color":"warning","dark":""}},[_c('v-alert',{attrs:{"type":"warning"}},[_vm._v(" You do not have permissions to access this feature. Contact Dealer Insights support for further assistance. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.goHome()}}},[_vm._v(" Close ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }