<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-material-card
      icon="mdi-alarm-multiple"
      title="Mailers"
      color="secondary"
      style="background-color: #FAFAFA"
    >
      <v-row>
        <v-col class="text-right">
          <a href="https://api.dealerinsights.com/scheduled_reports.pdf" target="_blank">
              How are these reports generated?
          </a>
        </v-col>
      </v-row>
        <v-col>
          <v-card>
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="items"
              :items-per-page="50"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
            >
              <template #item.query_type="{value}">
                {{ capitalizeFirstLetter(value) }}
              </template>
              <template #item.frequency="{value}">
                {{ capitalizeFirstLetter(value) }}
              </template>
              <template #item.last_run_date="{value}">
                {{ formatIsoDate(value, true) }}
              </template>
              <template #item.next_run_date="{value}">
                {{ formatIsoDate(value, true) }}
              </template>
              <template v-slot:item.scheduled_dms_report_files="{ item }">
                <router-link
                  :to="{ name: 'Mailer Files', params: { report_id: item.report_id } }"
                >
                  {{ formatNumber(item.scheduled_dms_report_files.length) }}
                </router-link>
              </template>              
              <template v-slot:item.actions="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">                
                    <v-icon
                      small
                      @click="runReport(item)"
                      v-on="on"
                    >
                      mdi-reload
                    </v-icon>
                  </template>
                  <span>Run Report Now</span>
                </v-tooltip>                 
                <router-link
                  style="text-decoration: none; color: inherit;"
                  :to="{ name: 'Edit Mailer', params: { report_id: item.report_id } }"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        small
                        class="mr-2"
                        v-on="on"
                      >
                        mdi-pencil
                      </v-icon>
                  </template>
                  <span>Edit Mailer</span>
                </v-tooltip>                           
                </router-link>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">                
                    <v-icon
                      small
                      @click="deleteItem(item)"
                      v-on="on"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Delete Mailer</span>
                </v-tooltip>                        
              </template>              
            </v-data-table>
          </v-card>
        </v-col>
    </base-material-card>
    <v-dialog
      v-model="showErrorDialog"
      persistent
      max-width="600"
      @keydown.esc="showErrorDialog = false"
    >
      <error-message
        :error="error"
        @closeErrorDialog="closeErrorDialog"
      />
    </v-dialog>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="#FF5E40"
        dark
      >
        <v-card-text>
          {{ loadingMessage }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="noPermissions"
      persistent
      width="600"
    >
      <v-card
        color="warning"
        dark
      >
        <v-alert
          type="warning"
        >
          You do not have permissions to access this feature. Contact Dealer Insights support for further assistance.
        </v-alert>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="goHome()"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import NetworkService from '@/services/NetworkService.js'
  import ErrorMessage from '@/components/ErrorMessage.vue'
  import DmsService from '@/services/DmsService.js'  
  import { mapGetters } from 'vuex'
  import { pick } from '@/mixins/exportMixin.js'
  import { formatterMixin } from '@/mixins/formatterMixin.js'
  import titleMixin from '@/mixins/titleMixin.js'

  export default {
    name: 'Mailer',
    components: {
      ErrorMessage,
    },
    mixins: [formatterMixin, titleMixin],
    data () {
      return {
        sortBy: 'frequency',
        sortDesc: true,
        loadingMessage: 'Loading',
        showErrorDialog: false,
        items: [],
        loading: true,
        error: '',
        expanded: [],
        noPermissions: false,
        headers: [
          { text: 'Title', value: 'title'},
          { text: 'Report Type', value: 'query_type', align: 'center' },
          { text: 'Frequency', value: 'frequency', align: 'center' },
          { text: 'Last Run Date', value: 'last_run_date', align: 'center' },
          { text: 'Next Run Date', value: 'next_run_date', align: 'center' },
          { text: 'FTP Server', value: 'ftp_server', align: 'center' },
          { text: 'Report Files', value: 'scheduled_dms_report_files', align: 'center' },
          { text: 'Actions', value: 'actions', sortable: false },          
        ],
      }
    },
    computed: {
      parsedItems () {
        const parsedHeaders = this.headers.map(h => h.value)
        if (this.items) {
          return this.items.map(i => pick(i, parsedHeaders))
        }
        return []
      },
      ...mapGetters(['accountId', 'accountName', 'permissions']),
    },
    watch: {
      accountId () {
        this.loadReports()
      },
    },
    created () {
      this.loadReports()
    },
    methods: {
      closeErrorDialog: function () {
        this.showErrorDialog = false
      },
      loadReports: function () {
        this.loadingMessage = 'Loading Reports'
        this.items = []
        this.loading = true
        DmsService.getScheduledReports()
          .then(response => {
            this.items = response.data
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      goHome: function () {
        this.$router.push({ name: 'Home' })
      },
      deleteItem (item) {
        const index = this.items.indexOf(item)
        if (confirm('Are you sure you want to delete this Mailer?') && this.items.splice(index, 1)) {
          // Call the API to update.
          this.loadingMessage = 'Deleting Mailer'
          this.loading = true
          DmsService.deleteScheduledReport(item.report_id)
            .then(response => {
              this.loading = false
            })
            .catch(error => {
              this.loading = false
              this.error = NetworkService.getErrorMessage(error)
              this.showErrorDialog = true
            })
        }
      },
      runReport (item) {
        const index = this.items.indexOf(item)
        this.loadingMessage = 'Generating Mailer File...'
        this.loading = true
        DmsService.runScheduledReport(item.report_id)
          .then(response => {
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },      
    },
  }
</script>
